/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';

.chart-type {
  height: remToBase(1.66);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  &.sm {
    width: remToBase(1.0416);
    height: remToBase(1.0416);
    display: inline-block;
    margin-right: remToBase(0.5);
    vertical-align: middle;
  }
  color: $clr-black !important;
  // Default state - gray
  filter: invert(40%) sepia(0%) saturate(2500%) hue-rotate(320deg);
  // Radio button selector - primary button color
  &.selector {
    filter: invert(20%) sepia(80%) saturate(3500%) hue-rotate(185deg);
  }
  // Active/Selected state - inverted over primary color
  &.active,
  &.selected {
    filter: invert(1);
    background: transparent !important;
  }
}

cds-icon[shape='dpa-horizontal_group-chart'] {
  transform: rotate(90deg);
}

.automation-action-icon {
  height: remToBase(1.7);
  width: remToBase(1.7);

  &.extra-small {
    height: remToBase(0.7);
    width: remToBase(0.7);
  }

  &.small {
    height: remToBase(1);
    width: remToBase(1);
  }

  &.medium {
    height: remToBase(1.2);
    width: remToBase(1.2);
  }

  &.round {
    border-radius: 50%;
  }
}

.ws-intel-logo {
  background-image: url('../assets/icons/vendors/svg/ws-intel-logo.svg');
  position: relative;
  top: px(2);
  &.is-remediation-mode {
    background-image: url('../assets/icons/vendors/svg/vm-logo.svg');
  }
  &.is-solid {
    background-image: url('../assets/icons/vendors/svg/ws-intel-logo-solid.svg');
  }
}

// Clarity override (min-width: 769px)
@media screen and (min-width: remToBase(48.06)) {
  .ws-intel-text {
    background-image: url('../assets/icons/vendors/svg/ws-intel-text.svg');
    &.is-remediation-mode {
      background-image: url('../assets/icons/vendors/svg/vmware-ws1-text.svg');
    }
  }
}

.user-flow-crumb-icon {
  width: remToBase(1);
  height: remToBase(1);
  &[data-event-type='START'] {
    background-image: url('../assets/icons/user-flow-crumbs/home.svg');
  }
  &[data-event-type='SYSTEM_DEFINED'] {
    background-image: url('../assets/icons/user-flow-crumbs/mobile-line.svg');
  }
  &[data-event-type='USER_DEFINED'] {
    background-image: url('../assets/icons/user-flow-crumbs/user-line.svg');
  }
  &[data-event-type='NETWORK'] {
    background-image: url('../assets/icons/user-flow-crumbs/cloud-traffic-line.svg');
  }
  &[data-event-type='ERROR'] {
    background-image: url('../assets/icons/user-flow-crumbs/error-standard-solid.svg');
  }
}

.connection-icon {
  &[data-service-type='OPENAPI'] {
    background-image: url('../assets/icons/vendors/svg/generic-openapi.svg');
  }
  &[data-service-type*='SLACK'] {
    background-image: url('../assets/icons/vendors/svg/slack.svg');
    transform: scale(1.9);
  }
  &[data-service-type='SERVICE_NOW_V1'] {
    background-image: url('../assets/icons/vendors/svg/service-now.svg');
  }
  &[data-service-type='HTTP'] {
    background-image: url('../assets/icons/vendors/svg/http.svg');
  }
  &[data-service-type='LOOKOUT'] {
    background-image: url('../assets/icons/vendors/svg/lookout.svg');
    position: relative;
  }
  &[data-service-type='WS_ONE_MTD'],
  &[data-service-type='WORKSPACE_ONE_MOBILE_THREAT_DEFENSE'] {
    background-image: url('../assets/icons/vendors/svg/ws-one-mtd.svg');
  }
  &[data-service-type='CARBONBLACK'] {
    background-image: url('../assets/icons/vendors/svg/carbonblack.svg');
  }
  &[data-service-type='NETSKOPE'] {
    background-image: url('../assets/icons/vendors/svg/netskope.svg');
  }
  &[data-service-type='AIRWATCH'],
  &[data-service-type='UEM'],
  &[data-service-type='AIRWATCH_API_V1'],
  &[data-service-type='DISCOVERY'],
  &[data-service-type='WORKSPACE_ONE_UEM'] {
    background-image: url('../assets/icons/vendors/svg/ws1-uem.svg');
  }
  &[data-service-type='INTERNAL'],
  &[data-service-type='EMPLOYEE_EXPERIENCE'],
  &[data-service-type='APTELIGENT'] {
    background-image: url('../assets/icons/vendors/svg/ws1-intel.svg');
  }
  &[data-service-type='IDM'],
  &[data-service-type='WS1_ACCESS'] {
    background-image: url('../assets/icons/vendors/svg/idm.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='HORIZON'] {
    background-image: url('../assets/icons/vendors/svg/horizon.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='WS1_BROKER'] {
    background-image: url('../assets/icons/vendors/svg/ws1-broker.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='HUB'] {
    background-image: url('../assets/icons/vendors/svg/hub.svg');
    background-repeat: no-repeat;
    transform: scale(1.2);
  }
  &[data-service-type='HUB_SERVICES'] {
    background-image: url('../assets/icons/vendors/svg/hub.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='ASSIST'],
  &[data-service-type='WS1_ASSIST'] {
    background-image: url('../assets/icons/vendors/svg/assist.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='AZURE'] {
    background-image: url('../assets/icons/vendors/svg/azure.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='BETTERMOBILE'] {
    background-image: url('../assets/icons/vendors/svg/bettermobile.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='CHECKPOINT'] {
    background-image: url('../assets/icons/vendors/svg/checkpoint.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='CROWDSTRIKE'] {
    background-image: url('../assets/icons/vendors/svg/crowdstrike.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='EXTREME_NETWORKS'] {
    background-image: url('../assets/icons/vendors/svg/extremenetworks.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='MICROSOFT'] {
    background-image: url('../assets/icons/vendors/svg/microsoft.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='PRADEO'] {
    background-image: url('../assets/icons/vendors/svg/pradeo.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='WANDERA'] {
    background-image: url('../assets/icons/vendors/svg/wandera.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='ZIMPERIUM'] {
    background-image: url('../assets/icons/vendors/svg/zimperium.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='ZOOM'] {
    background-image: url('../assets/icons/vendors/svg/zoom.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='UAG'] {
    background-image: url('../assets/icons/vendors/svg/uag.svg');
    background-repeat: no-repeat;
  }
  &[data-service-type='SERVICENOW_ITSM'] {
    background-image: url('../assets/icons/vendors/svg/itsm.svg');
    background-repeat: no-repeat;
  }
}

.intelligent-hub {
  background: {
    image: url('../assets/icons/vendors/svg/ws1-hub-logo.svg');
    position: center center;
    repeat: no-repeat;
    size: contain;
  }
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: x(9);
  width: x(40);
}

.start {
  background-image: url('../assets/icons/vendors/svg/start.svg');
}

.filter {
  background-image: url('../assets/icons/vendors/svg/filter.svg');
}

.action {
  background-image: url('../assets/icons/vendors/svg/action.svg');
}

.empty-state {
  background-image: url('../assets/icons/vendors/svg/empty-state.svg');
}

.robot {
  background-image: url('../assets/icons/vendors/svg/robot.svg');
}

.data-access-policy {
  background-image: url('../assets/icons/data-access-policy.svg');
}

.dpa-icon {
  background: {
    repeat: no-repeat;
    position: center center;
    size: contain;
  }
}

.action-icon-container {
  &.circle {
    width: remToBase(1);
    height: remToBase(1);
    background-color: $clr-white;
    text-align: center;
  }
}

.SLACK-icon,
.slack-icon {
  background-image: url('../assets/icons/vendors/svg/slack.svg');
}

.idm-icon,
.IDM-icon {
  background-image: url('../assets/icons/services/vm.svg');
}

.SERVICE_NOW_V1-icon,
.servicenow-icon {
  background-image: url('../assets/icons/vendors/svg/service-now.svg');
}

.AIRWATCH_API_V1-icon,
.ws1uem-icon {
  background-image: url('../assets/icons/services/uem.svg');
}

.android {
  background-image: url('../assets/icons/platforms/android.svg');
}

.apple,
.ios {
  background-image: url('../assets/icons/platforms/apple.svg');
}

.blackberry {
  background-image: url('../assets/icons/platforms/blackberry.svg');
}

.macos {
  background-image: url('../assets/icons/platforms/macos.svg');
}

.tvos {
  background-image: url('../assets/icons/platforms/tvos.svg');
}

.webapp {
  background-image: url('../assets/icons/platforms/webapp.svg');
}

.winrt,
.windowsmobile,
.windows,
.desktop {
  background-image: url('../assets/icons/platforms/windows.svg');
}

.windowsphone8,
.windowsphone {
  background-image: url('../assets/icons/platforms/windows-phone.svg');
}

.windowspc {
  background-image: url('../assets/icons/platforms/windows-desktop.svg');
}

.windows7 {
  background-image: url('../assets/icons/platforms/windows7.svg');
}

.windowsrugged {
  background-image: url('../assets/icons/platforms/windows-rugged.svg');
}

.chromebook,
.chromeos {
  background-image: url('../assets/icons/platforms/chrome.svg');
}

.app-loads-list {
  background-image: url('../assets/icons/app-loads/app-loads-list.svg');
}

.app-loads-mobile {
  background-image: url('../assets/icons/app-loads/app-loads-mobile.svg');
}

.add-dashboard {
  background-image: url('../assets/images/add-dashboard.svg');
}

.add-service-account {
  background-image: url('../assets/images/add-dashboard.svg');
}

.service-icon {
  &[data-service-type='HORIZON'] {
    background: url('../assets/icons/vendors/svg/horizon.svg') no-repeat;
  }

  &[data-service-type='HUB_SERVICES'] {
    background: url('../assets/icons/services/hub-services.svg') no-repeat;
  }

  &[data-service-type='WS1_ACCESS'] {
    background: url('../assets/icons/services/ws1-access.svg') no-repeat;
  }

  &[data-service-type='WS1_UEM'] {
    background: url('../assets/icons/services/ws1-uem.svg') no-repeat;
  }
}

.predefined-report {
  background: url('../assets/icons/common/predefined-report.svg') no-repeat;
  width: px(28);
  height: px(28);
}

.notification-service-icon {
  &[data-service-type='INTELLIGENCE'] {
    background: url('../assets/icons/vendors/svg/ws1-intel.svg') center/px(18) no-repeat;
    &.white {
      background: url('../assets/icons/notification-services/intelligence-white.svg') center/px(20) no-repeat;
    }
  }

  &[data-service-type='WS1_CLOUD'],
  &[data-service-type='INTELLIGENCE_DEEM'],
  &[data-service-type='INTELLIGENCE_MOBILE_THREAT_DEFENSE'],
  &[data-service-type='PRODUCTIVITY_APPS'] {
    background: url('../assets/icons/notification-services/ws1-cloud.svg') no-repeat;
    &.white {
      background: url('../assets/icons/user-panel/ws1-cloud-white.svg') center/px(20) no-repeat;
    }
  }

  &[data-service-type='WS1_ACCESS'] {
    background: url('../assets/icons/notification-services/ws1-access-2.svg') no-repeat;
    &.white {
      background: url('../assets/icons/notification-services/ws1-access-white.svg') center/px(20) no-repeat;
    }
  }

  &[data-service-type='WS1_UEM'] {
    background: url('../assets/icons/services/ws1-uem.svg') center/px(18) no-repeat;
    &.white {
      background: url('../assets/icons/notification-services/ws1-uem-white.svg') center/px(20) no-repeat;
    }
  }
}

.user-cards {
  background-image: url('../assets/images/user-cards.svg');
}

.user-card {
  background-image: url('../assets/images/user-card.svg');
}

.pre-existing-users {
  background-image: url('../assets/images/pre-existing-users.svg');
}

.cards {
  background-image: url('../assets/images/cards.svg');
}

.workspace-one-logo {
  background: url('../assets/icons/vendors/svg/workspace-one-logo.svg') no-repeat;
}

.workspace-one-logo-container.is-remediation-mode .workspace-one-logo {
  background-image: url('../assets/icons/vendors/svg/vm-logo.svg');
}

.feature-category-icon {
  &[data-feature-category-type='HORIZON_AZURE_SERVICE'] {
    background: url('../assets/icons/vendors/svg/horizon-service-azure.svg') no-repeat;
  }
}

.azure {
  background-image: url('../assets/icons/vendors/svg/azure-white.svg');
}

.integration-icon {
  background: url('../assets/icons/navigation-menu/integration.svg') no-repeat;
  &.selected {
    background: url('../assets/icons/navigation-menu/integration-highlighted.svg') no-repeat;
  }
}

.pendo-logo {
  background: url('../assets/icons/vendors/svg/pendo-logo.svg');
}

.ws1-uem-logo {
  background: url('../assets/icons/vendors/svg/ws1-uem.svg') no-repeat;
}

.rca-icon {
  background-image: url('../assets/images/rca.svg');
  width: px(120);
  height: px(120);
  min-width: px(120);
  &.rca-error {
    background-image: url('../assets/images/rca-error.svg');
    width: px(140);
    min-width: px(140);
  }
  &.rca-success {
    background-image: url('../assets/images/rca-success.svg');
    width: px(140);
    min-width: px(140);
  }
  &.rca-all-set {
    background-image: url('../assets/images/rca-all-set.svg');
    width: px(140);
    min-width: px(140);
  }
}

.incident-icon {
  background-image: url('../assets/images/incident.svg');
  width: px(16);
  height: px(16);
}

.pop-out-icon {
  background-image: url('../assets/images/pop-out.svg');
  height: x(4);
  width: x(4);
}
