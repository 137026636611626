/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

@import 'variables';
@import 'z-index';

dpa-bread-crumb-viewer h2.bread-crumb-header {
  font-size: px(22);
  a {
    color: $clr-dark-gray;
  }
  cds-icon {
    margin: 0 px(6);
  }
}

dpa-schedule-report {
  .modal-dialog {
    width: x(166);
  }
  .radio-date-picker .owl-dateTime-dialog {
    left: 0;
  }
  .modal-body-wrapper {
    overflow: unset; // This makes the dropdowns inside dpa-scheduler appear on top of the modal.
  }
  .owl-dateTime-customTemp {
    width: 100%;
  }
}

dpa-key-selector {
  .local-search {
    input {
      background: none !important;
      border-bottom: none !important;
      outline: none;
    }
  }
}

.lookup-variable-selector-container .key-selector-offset dpa-key-selector {
  // Override Quill button styles
  cds-icon {
    height: px(12) !important;
    width: px(12) !important;
    margin-top: 0 !important;
  }
  .footer-banner > button {
    float: none;
    height: auto;
    width: auto;
    padding: 0;
  }
  // Reduce key-selector width
  .alpha-col-elem-container {
    min-width: x(60);
  }
}

dpa-custom-report clr-modal form.copy-form .form-group .tooltip {
  width: 100%;
  & input {
    width: 100%;
  }
}

dpa-data-grid .datagrid-detail-pane {
  margin-top: 0;
}

dpa-table-chart .datagrid {
  margin-top: 0;
}

dpa-filter-results .datagrid-column-flex {
  white-space: nowrap;

  .datagrid-column-title {
    align-self: center;
  }
}

dpa-widget-tag,
dpa-filter-tags {
  .dropdown-menu {
    max-width: unset;
  }
}

dpa-automation-grid-list .datagrid .trigger-action-wrapper {
  padding-top: x(1.15);
  padding-bottom: x(1.15);
}

dpa-automation-list-modal,
dpa-automation-grid-list {
  .datagrid-host {
    margin-top: remToBase(1);
  }

  .datagrid-cell div:not(.radio) {
    overflow: hidden;
  }

  .signpost {
    .trigger-if {
      height: remToBase(1.242);
      .trigger-actions-more,
      .trigger-action {
        height: remToBase(1.2);
        width: remToBase(1.2);
      }
      .trigger-actions-more .count {
        font-size: remToBase(0.55);
      }
      .no-action {
        font-size: remToBase(0.6);
        line-height: remToBase(1.3);
      }
    }
    .quick-view-link {
      float: right;
      height: remToBase(0.9);
      display: flex;
      align-items: center;
    }
  }
}

dpa-report-preview-table {
  clr-datagrid {
    clr-dg-row {
      div.datagrid-row-sticky {
        height: 100%;
      }
    }
  }
}

clr-datagrid.footermode {
  margin-top: remToBase(1);
  .datagrid {
    display: none !important;
  }
  .datagrid-footer {
    border-top: px(1) solid $clr-light-midtone-gray;
    height: px(40);
  }
}

dpa-type-ahead,
dpa-type-ahead-multi {
  .type-ahead-dropdown {
    @include z-index-important(type-ahead-dropdown);
  }
}

dpa-admin-user-selector {
  .filtered-items {
    max-height: remToBase(12) !important;
  }
}

/* stylelint-disable no-descending-specificity */
dpa-repeat-trigger-options-dropdown {
  dpa-type-ahead {
    .input-arrow {
      color: currentColor;
    }
    .subgroup-container {
      .subgroup-title {
        font-size: px(12);
      }
    }
    .type-ahead-dropdown {
      border: px(1) solid $clr-light-midtone-gray !important;
      border-radius: px(3);
      .subgroup-container {
        padding-bottom: $x2;
      }
      .filtered-items {
        .filtered-item:hover:not(.focused) {
          background-color: $dpa-azure-white !important;
        }
      }
    }
  }
}

dpa-automation-select-template {
  .card-header {
    .entity + .matching-text {
      margin-left: remToBase(0.2083);
    }
  }
}

.matching-text > b {
  background-color: $dpa-matching-text;
}

dpa-header-meta + dpa-header-meta {
  margin-left: remToBase(1);
}

dpa-widget {
  .dashed-border {
    pointer-events: none; // Do not allow interact with widget during edit layout mode
    ngx-charts-chart path {
      pointer-events: none !important; // Fix Donut/Bar Charts where they have inline pointer-events: auto
    }
  }
}

dpa-share-report dpa-type-ahead-multi {
  width: 100% !important;
  flex: 1;
}

dpa-bookmarks {
  .bookmarks-filter {
    .type-ahead-dropdown {
      left: x(-5.5);
      .filtered-items-container {
        margin: $x2 0;
        width: x(29);
        .filtered-item {
          line-height: $x5;
        }
      }
    }
  }
}

dpa-sticky-header.sticky-header-with-table dpa-fluid-height.minimized {
  @include z-index(sticky-header);
}

dpa-administrators-home dpa-sticky-header,
dpa-integration-view dpa-sticky-header {
  .sticky-header.full {
    padding-bottom: 0;
  }
  .sticky-header-tabs.simple-tabs.minimized clr-dropdown {
    margin-top: 0 !important;
  }
}

dpa-service-opt-in-modal {
  clr-modal {
    clr-alert {
      margin-top: $x2;
    }
    .modal-content {
      margin: auto;
      max-width: x(113.5);
    }
    .modal-header {
      padding: 0;
    }
  }
}

dpa-trial-banner {
  .alert-app-level {
    .alert-text {
      font-size: $x2;
    }
    &.alert-danger {
      .alert-text {
        max-width: x(116);
      }
    }
    .close {
      margin: auto;
    }
  }
}

dpa-account-role-assignment {
  .card {
    min-height: x(40) !important;
  }
}

// allows changing chart font colors by changing color attribute of container
// ngx-chart library doesn't set this
dpa-standard-chart {
  svg text {
    fill: currentColor;
  }
}

dpa-solution-comment {
  .comment-content {
    > p {
      margin-top: 0;
    }
  }

  .dropdown .dropdown-toggle:not(.btn) {
    padding: 0;
  }
}

dpa-shared-users-info-tag {
  .signpost-content {
    max-width: remToBase(30);
  }
}

quill-view-html .ql-container .ql-editor {
  padding-left: 0;
}

quill-editor {
  .filters-container .label.label-light-blue {
    border: px(1) solid var(--clr-label-light-blue-bg-color) !important;
    padding: 0 $x2 0;
    width: auto;
  }
}

dpa-cookie-policy-banner {
  a.privacy-notice {
    color: $clr-near-white;
    text-decoration: underline;
    text-underline-position: under;
  }
}

.modal-body {
  dpa-admin-user-selector .selected-users {
    max-height: 25vh; // .modal-body max-height is 75vh;
  }
}

// required to display date range selector in column view when CUSTOM option is selected
dpa-dashboard-view {
  dpa-widget {
    dpa-widget-control-bar {
      dpa-trend-date-range {
        .date-range-container {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.side-panel-wrapper.expanded {
  > .side-panel-container {
    top: 0 !important;
    padding-bottom: x(10);
  }
}

dpa-automation-overview-summary {
  dpa-card {
    .card {
      min-height: remToBase(8) !important;

      .card-block {
        &:first-child {
          justify-content: flex-start;
        }
      }
    }

    .dpa-card-icon + div {
      padding: 0 !important;
    }

    .total-results {
      text-align: center;
    }
  }
}

dpa-quickflows-split-view-details,
dpa-workflows-split-view-details {
  .nav {
    padding: 0 $x4;
    background: $clr-white;
  }
}

dpa-incidents-dashboard {
  .sidenav {
    border: 0;
    max-width: 100%;
    width: 100%;
    .nav-group {
      label {
        font-size: px(24);
        font-weight: 300;
      }
    }
  }
}

ngx-charts-heat-map .y.axis .tick text {
  font-family: system-ui, serif;
}

dpa-deem-incident-detail-header {
  dpa-sticky-header {
    div.sticky-header.full {
      padding-bottom: 0;
    }
  }
}

dpa-tree-map-drilldown {
  .split-view-list-component {
    border-right: none !important;
    .search-container {
      justify-content: flex-start !important;
      padding-left: remToBase(0.6) !important;
    }
  }
}

dpa-app-ux-score {
  dpa-standard-widget.score-by-version {
    .card-block {
      > dpa-standard-chart {
        dpa-table-chart {
          height: px(525);
        }
        > dpa-timeout-loader {
          height: px(536);
        }
      }
    }
  }
}

dpa-deem-contributor > dpa-deem-filter-group-date-range .dashboard-filter-container {
  margin-top: 0 !important;
}

dpa-deem-contributor-detail > dpa-standard-chart > dpa-table-chart {
  padding: px(30) px(2) px(1) px(2) !important;
  margin-bottom: 0 !important;
}

.dpa-wizard-open dpa-global-header-search {
  display: none;
}

dpa-global-header-search .global-search-dropdown {
  min-width: min(80vw, #{px(700)});
}

.dpa-sticky-header .sticky-header {
  .sticky-header-content {
    .dashboard-filter-container {
      margin-top: x(1.5);
    }
  }
}

dpa-panels {
  dpa-panel:not(:only-child):not(:first-child) {
    .panel {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }

  dpa-panel:not(:only-child):not(:last-child) {
    .panel {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .panel.expanded {
    .panel-header.toggleable,
    .trigger-rules-panel-header {
      border-bottom: none;
    }
  }
}

.leaflet-popup {
  margin-bottom: px(26);
}

dpa-trigger-options {
  .repeat-trigger-card,
  .scheduler-card {
    width: auto;
  }
  .column-layout {
    &,
    .form-container {
      flex-direction: column;
    }
  }
}

dpa-widget-edit-range-dialog {
  .modal-body {
    overflow: visible;
  }
}

.datagrid-row-flex .datagrid-row-detail .datagrid-cell {
  padding: x(1.5);
}

dpa-rich-text-form-field clr-control-container,
dpa-column-textarea-form-field clr-control-container {
  .clr-input-wrapper {
    max-height: inherit;
  }
}

clr-range-container {
  .slider {
    width: 100%;
  }
}

dpa-devices-desktop-performance-v2-dashboard {
  dpa-standard-widget:first-of-type {
    .card.standard-widget {
      margin-top: 0;
    }
    .standard-chart.auto {
      height: unset !important;
    }
    dpa-ratio-indicator {
      .indicator-container {
        position: unset !important;
        display: inline-flex;
      }
    }
  }
}

dpa-leaderboard {
  dpa-ratio-indicator {
    .indicator-container {
      position: unset !important;
      display: inline-flex;
    }
  }
}

.automation-summary-card-placeholder {
  display: flex;
  margin-inline: auto;
  margin-block: px(100);
}

.date-time-picker.open {
  @include z-index(date-time-picker);
}

dpa-tooltip-view {
  @include z-index-important(dpa-tooltip-view);
}

dpa-intelligence-workflow {
  height: 100%;
}

dpa-automation-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// reset the container height when page level alert banner is visible
$PAGE_BANNER_HEIGHT: px(65);

main.content-area {
  &.banner-visible {
    dpa-intelligence-workflow {
      height: calc(100% - #{$PAGE_BANNER_HEIGHT});
      display: block;
    }
  }
}

dpa-user-profile-security {
  dpa-simple-map-card {
    div.map {
      height: px(524) !important;
    }
  }
}

dpa-deem-threshold-edit-modal {
  .modal-header--accessible {
    padding: px(24) px(24) 0 px(24);
  }

  .modal-dialog .modal-content {
    padding: 0;
  }

  .modal-body {
    padding: 0;
  }
}

dpa-insight-comments-modal {
  .ql-container {
    max-height: px(200);
  }

  .ql-editor {
    max-height: px(200);
    min-height: px(150);
  }
}

dpa-system-limits-modal {
  clr-modal {
    .modal-header,
    .modal-header--accessible {
      padding-bottom: x(2);
    }
  }
}

dpa-system-limits,
dpa-user-system-limits {
  dpa-usage-card {
    .card {
      border: none;
      box-shadow: none;
    }
  }
}

dpa-deem-expandable-card {
  dpa-ratio-indicator {
    .indicator-bar {
      top: 0 !important;
      bottom: 0 !important;
      border-radius: 0 !important;
    }
  }
}

dpa-filter-group-date-range .query-builder {
  padding-left: px(5);
}

dpa-connector-add-modal clr-datagrid {
  height: x(60);
}

.trigger-options-config-container {
  :is(h6, label, .clr-control-label) {
    font-weight: normal;
  }

  .scheduler-container {
    .clr-control-label {
      width: px(100) !important;
    }
  }

  .clr-form-control {
    min-width: px(300) !important;
  }
}

dpa-connector-action-test-modal {
  .accordion .accordion-body {
    padding: 0 !important;
  }
}

dpa-modal.no-content-padding {
  .modal-dialog {
    .modal-content {
      padding: 0;

      .modal-header--accessible {
        padding: x(4);
      }

      .modal-body-wrapper {
        padding: 0;
      }
    }
  }
}

dpa-deem-hub-notification-overview-actions-widget,
dpa-deem-hub-notification-action-type-widget {
  dpa-widget-subheader {
    .metric-percent {
      width: auto !important;
    }
  }
}

dpa-insights-feed-timeline {
  dpa-timeline {
    max-height: px(325) !important;
  }
}

.score-summary-style.datagrid-cell {
  display: block !important;
  width: remToBase(11);
  &.datagrid-hidden-column {
    display: none !important;
  }
}

dpa-device-profile-overview {
  dpa-standard-widget {
    .card {
      border-top: none;
      border-bottom: none;
    }
    .card-block {
      border: none;
    }
    dpa-unstacked-line-chart {
      .ngx-charts-outer {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  dpa-standard-widget.zoom-network-widget dpa-standard-widget.score-timeline {
    .card {
      border-top: 1px solid $clr-light-midtone-gray;
    }
  }
  dpa-slide-over {
    .content-wrapper {
      width: px(1000) !important;
    }
  }
  dpa-device-profile-timeline-summary {
    clr-datagrid {
      height: auto !important;
    }
  }
}

dpa-create-custom-role-modal {
  clr-datagrid {
    clr-dg-row {
      clr-dg-cell.datagrid-cell {
        padding-top: px(6) !important;
        padding-bottom: px(6) !important;
      }
    }
  }
}

dpa-admin-roles {
  dpa-data-grid .notselectable dpa-admin-roles-detail-view-users dpa-data-grid .datagrid-select {
    display: flex;
  }
}

dpa-carousel-v2.smaller-dots {
  dpa-carousel-page-v2 {
    width: 100% !important;
  }

  .carousel-dots-container {
    margin-top: x(1);
    bottom: 0;

    .dot-container {
      padding: px(3) !important;

      .dot {
        height: px(10) !important;
        width: px(10) !important;
      }
    }
  }
}

dpa-cve-insight-card .card-block .carousel-body {
  margin-bottom: 0;
}

dpa-deem-metric-overview-card {
  dpa-standard-chart {
    height: px(30) !important;
    width: px(70) !important;
    dpa-unstacked-line-chart {
      padding: 0 !important;
    }
  }
}

dpa-widget-builder-v2 dpa-select-template {
  .list-container-inner {
    overflow: auto;
  }
}

.homepage-section-panel {
  .panel {
    border: none !important;
    background: transparent !important;

    .homepage-section-label {
      font-size: px(28);
      font-weight: 300;
      color: $clr-color-neutral-900;
    }
  }
}

dpa-slide-over {
  dpa-full-page {
    @include z-index-important(page-right-panel);
  }
}

// clarity v15 fix
a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

// For StickyHeaderV2 the font size is changed to 12px, So this change is done to keep header-meta font-size same for old sticky header
dpa-sticky-header {
  dpa-header-meta {
    .header-meta-item {
      .header-label,
      span {
        font-size: px(14) !important;
      }
    }
  }
}

.btn-group {
  .btn:disabled:not(.chart-button) {
    border: none;
  }
}

dpa-deem-test-details dpa-filter-group-date-range .dashboard-filter-container {
  margin-top: 0 !important;
}

// Data-explorer
.as-horizontal > .as-split-gutter {
  height: auto !important;
  .as-split-gutter-icon {
    background-position: top !important;
    margin-top: x(90);
  }
}

dpa-data-explorer-tabs clr-tabs ul {
  padding-left: x(4) !important;
}

dpa-edit-theme-modal {
  .modal-body-wrapper {
    overflow: visible;
  }
}

dpa-card {
  &.data-source-card {
    min-height: px(357);
  }
}

// Remove branding on top of primary nav hamburger menu when open
clr-main-container.open-hamburger-menu dpa-global-header .branding {
  visibility: hidden;
}

dpa-trial-services-modal {
  .modal-body-wrapper {
    padding: 0;
  }
}

// Adding Ellipses to title in notification center
dpa-notification-detail-view-header {
  .bread-crumbs-container {
    width: 100%;
    .bread-crumbs-inner {
      width: 100%;
      .page-title-container {
        max-width: 100%;
        h1 {
          @include overflow-ellipsis();
        }
      }
    }
  }
}

dpa-deem-incident-detail {
  .page-wrapper {
    .space-maker {
      min-height: remToBase(1) !important;
      height: 0 !important;
    }
  }
}

dpa-deem-incident-detail-overview {
  dpa-insight-dashboard {
    dpa-standard-widget {
      .card {
        margin-top: 0;
      }
    }
  }
}

dpa-data-explorer {
  dpa-data-explorer-visualization {
    dpa-aggregation-function-picker {
      @include z-index(key-selector-dropdown-container);
    }
  }
}

dpa-partner-generate-credentials-modal {
  .modal-body {
    min-height: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.playbook-builder-step-item-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .clr-form {
    label {
      font-size: px(13);
      font-weight: $clr-font-weight-semibold;
      line-height: px(18);
    }
    .clr-textarea {
      height: px(80);
      width: calc(100% - px(24));
    }

    input {
      width: calc(100% - px(24));
    }
    .clr-required-mark {
      display: inline-flex;
    }
  }
}

dpa-modal.test-action-container {
  dpa-fluid-height:last-child .accordion-body {
    padding: 0 !important;
  }
}

ngx-json-viewer {
  display: inline-block;
}

.ngx-json-viewer {
  .segment-value {
    color: unset !important;
  }
}

dpa-deem-score-analysis-card {
  dpa-metric-card {
    .value-view > .value {
      font-size: unset !important;
    }

    .metric-card {
      padding: 0 !important;
    }
  }
}

dpa-device-profile-destination-summary {
  .datagrid-table .datagrid-column.datagrid-fixed-width {
    flex: 1 1 auto;
  }
  .datagrid-table .datagrid-cell.datagrid-fixed-width {
    flex: 1 1 auto;
  }
}

dpa-deem-sam {
  dpa-deem-sam-compare {
    dpa-deem-sam-add-apps-slide-over {
      dpa-deem-sam-preview {
        .card.no-border-bottom {
          margin-top: 0;
        }
        .datagrid-host {
          display: block;
        }
      }
    }
  }

  dpa-report-preview-table {
    dpa-no-search-results {
      padding-bottom: $x10;
    }
  }
}

dpa-deem-playbook-builder-step-widget,
dpa-deem-playbook-builder-step-report {
  dpa-slide-over {
    dpa-full-page {
      top: 0 !important;
    }
  }
}

dpa-deem-virtual-session-details-dashboard-v2 .card-block > clr-tabs > ul.nav {
  margin: 0 px(-18);
  padding-left: px(18);
  width: auto;
}

dpa-deem-virtual-session-details-performance .metric-card-container {
  padding-top: 0;
}

dpa-custom-attribute-filter-group {
  dpa-query-builder {
    .rule-group {
      width: 100%;
    }
  }
}
