/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

@use 'sass:math';
@import 'variables';
@import 'z-index';

/* stylelint-disable no-descending-specificity */
.go-filter-group {
  position: relative;

  &:not(.show-filter-text) {
    .filter-group-root .filter-group-text-view-container {
      display: none !important;
    }
  }

  .filter-group-text-view-container {
    padding: 0;
    border-bottom: 0 !important;
  }

  .filter-group-text-view {
    border: px(1) solid $clr-color-neutral-400;
  }
}

dpa-go-canvas {
  .reset-node-visibility {
    // When changing workflow settings, hide all other elements while loading occurs
    .canvas-view > *:not([data-node-type='workflow-settings']) {
      visibility: hidden;
    }
  }

  .node-add-new-button {
    visibility: hidden;
  }
  &.is-visible {
    .node-add-new-button {
      visibility: visible;
    }
  }
}

@media (max-width: 1340px) {
  .go-canvas-view-container {
    dpa-go-canvas-node-selector {
      display: none;
    }
    dpa-go-canvas-node-popup .canvas-popup-container {
      width: 54vw;
    }
  }
}

dpa-go-canvas-node-popup {
  // Displace the vertical canvas padding + canvas zoom controls + nav header height when positioning the canvas node popup panel
  $canvas-padding: 24;
  $nav-header-height: 120;
  $panel-header: 230;
  $panel-template-tags: 144;
  $panel-height: $panel-header + ($canvas-padding * 2) + $nav-header-height;

  .panel {
    max-width: px(800);

    .select-template-container {
      height: auto;
    }

    .list-container {
      .scroll-container,
      .list-wrapper {
        overflow: auto;
      }
      .scroll-container {
        max-height: calc(100vh - #{px($panel-height + $panel-template-tags)});
      }
      .list-wrapper {
        max-height: calc(100vh - #{px(520)});
      }
    }

    .list-wrapper {
      .scroll-container {
        max-height: none;
      }
    }
  }
  .canvas-popup-container {
    top: px(80);
    right: px($canvas-padding);
    bottom: px($canvas-padding);
  }

  dpa-go-select-template {
    dpa-generic-list {
      dpa-filter-tags {
        .filters-row {
          margin-right: px(120);
          clr-dropdown {
            clr-dropdown-menu {
              max-height: px(300);
              overflow: auto;

              .dropdown-item,
              .quick-filters {
                flex-shrink: 0;
              }

              .drop-down-option-clear {
                width: px(12);
              }
            }
          }
        }
      }
    }
  }
}

dpa-go-add-edit-header {
  .workflow-name-container {
    dpa-inline-edit {
      div[contenteditable] {
        min-width: x(40) !important;
        max-height: px(100);
        overflow: auto;
        word-break: break-word;
        padding-inline: px(10);
      }

      &.automation-description cds-icon {
        padding-bottom: px(24) !important;
      }
    }
  }
}

dpa-go-template-preview {
  .modal-dialog {
    .modal-content {
      padding: 0;

      > div {
        padding-inline: x(4.8);
      }

      > div:first-child {
        padding-top: x(2);
        padding-bottom: x(2);
        border-bottom: px(1) solid $clr-color-neutral-400;
        background-color: $clr-light-gray;

        dpa-modal-title {
          font-size: x(2.5);
        }
      }

      > div:last-child {
        padding-block: $x4;
        border-top: px(1) solid $clr-color-neutral-400;
      }

      .filter-group-text-view {
        border: px(1) solid $clr-color-neutral-400;
        border-radius: px(2);
      }
    }
  }
}

dpa-go-add-edit-sidepanel {
  dpa-automation-action-method-selector {
    .type-ahead-container {
      dpa-local-search {
        form > .clr-row {
          flex-direction: row-reverse;

          > div:first-child {
            position: relative;
            right: px(25);
          }

          .input-wrapper {
            padding: 0;
          }
        }
      }
    }

    .scrollable-actions-container {
      max-height: fit-content !important;
      overflow: auto !important;

      &.two-column-container {
        .action-item {
          width: 100% !important;
          &.active {
            border: px(1) solid $clr-color-neutral-400;
            background-color: $clr-light-gray;
          }
        }
      }
    }
  }
}

dpa-go-canvas-view,
:is(dpa-go-workflow-group-task, dpa-go-workflow-action-task) {
  position: relative;

  :is(dpa-automation-connector-action-settings-editor) {
    dpa-sections-meta-form > form > h3:not(:first-of-type) {
      margin-top: $x4 !important;
    }

    .form-container {
      padding: $x4 $x4 0 !important;

      label {
        font-weight: normal;
      }

      h3 {
        font-weight: normal;
        margin-top: 0 !important;
      }

      .test-actions-container {
        margin-inline: px(-25);
        border-top: px(1) solid $clr-color-neutral-400;
        margin-top: $x3;
        padding-inline: px(20);
        padding-block: px(10);
      }

      textarea {
        border: px(1) solid var(--clr-forms-border-color) !important;
        padding: $x2 !important;
      }

      input,
      textarea {
        font-size: x(2.16);
        font-weight: 200;
        line-height: $x4;
        padding-right: $x8 !important;
      }

      dpa-lookup-variable-input {
        .menu-opener-container {
          cds-icon {
            transform: scale(0.75);
            right: $x3 !important;
          }
        }
      }
    }
  }

  .actions-container {
    .cdk-drag-preview {
      box-sizing: border-box;
      border-radius: px(4);

      dpa-panel {
        .panel {
          background-color: $clr-action-blue-lightest;
          border: px(2) dashed $clr-color-neutral-400 !important;
        }
      }
    }

    .cdk-drag-placeholder {
      opacity: 0;
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.cdk-drop-list-dragging &:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

dpa-go-action-card {
  dpa-panel {
    .panel.expanded {
      box-shadow:
        0 px(2) px(4) rgb(0 0 0 / 5%),
        0 px(8) px(16) rgb(0 0 0 / 5%) !important;
    }
  }
}

dpa-split-view-details {
  dpa-go-add-edit-main .node {
    pointer-events: none;
  }
}

dpa-go-add-edit-main {
  .query-builder.read-only-mode .rule-text {
    pointer-events: none;

    .key {
      pointer-events: auto;
    }
  }
  .go-canvas-view-container.read-only-mode {
    min-height: px(640);
    height: px(640);

    .canvas-popup-container {
      top: px(24);
    }
    .component-workflow,
    .node-options-toggle {
      display: none;
    }

    .node-reorder-icon {
      pointer-events: none;
    }
  }
}

.workflow-condition-container {
  .content {
    .go-filter-group {
      margin: 0;
    }
  }
}

dpa-go-workflow-tasks {
  .workflow-tasks-container {
    .cdk-drag-preview {
      box-sizing: border-box;
      border-radius: px(4);

      .workflow-task {
        border: px(1) dashed $clr-color-neutral-400 !important;
        border-radius: px(4);
      }
    }

    .cdk-drag-placeholder {
      opacity: 0;
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.cdk-drop-list-dragging &:not(.cdk-drag-placeholder) {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

dpa-go-list-view {
  .root-add-task-menu {
    clr-dropdown-menu {
      width: px(160);
    }
  }
  .read-only-mode.list-view-content {
    margin-top: 0;
  }
}

// block adding nested conditions more than allowed
dpa-go-workflow-condition-task {
  > dpa-panel {
    &.block-nesting {
      dpa-go-workflow-add-task-menu {
        clr-dropdown-menu {
          a.condition {
            display: none;
          }
        }
      }
    }
    clr-alert {
      .alert {
        margin: x(2);
      }
    }
  }
}

dpa-go-filter-group {
  .go-filter-group.read-only-mode .filter-group-text-view-container.detail-view {
    border: none !important;
  }
}

// GO - template selection panel changes
dpa-go-select-template,
dpa-widget-builder-v2 {
  dpa-select-template {
    dpa-grouped-list {
      .group-list,
      .details-list {
        padding: 0;

        .list-item {
          height: px(38) !important;
          border-radius: 0;
          padding-inline: $x4 !important;

          .service-icon {
            height: px(20);
            width: px(20);
          }

          .header-content {
            font-weight: normal !important;
          }
        }

        .scroll-content {
          padding: 0;
        }
      }

      .details-list {
        .list-item {
          .header-content {
            color: $clr-action-blue;
          }
        }
      }
    }
  }
}

dpa-go-workflow-actions-preview {
  .trigger-action-summary {
    .rule-summary {
      dpa-dynamic-form {
        dpa-dynamic-form-node {
          dpa-read-only-form-field {
            clr-input-container {
              label.clr-control-label {
                max-width: unset;
                width: unset;
                flex: 1;
              }

              &.rich-text-editor {
                .clr-input-wrapper {
                  max-height: unset;
                }
              }
            }
            .clr-control-container {
              max-width: unset;
              width: unset;
              flex: 2;
            }
          }
        }
      }
    }
  }

  .stop-workflow-node {
    .delete-action-btn {
      display: none;
    }
  }
}

dpa-go-canvas-root {
  .go-canvas-root {
    .canvas-view {
      .nodes-container {
        .canvas-node {
          width: px(340);
          padding: 0;
          background: $clr-color-neutral-0;
          color: $clr-color-neutral-1000;
          border: px(1) solid $clr-color-neutral-400;
          border-radius: px(6);
          align-items: center;
          line-height: px(20);

          .node-content {
            min-height: px(70);
            padding: px(16);
            flex: 1;
          }

          &.selected {
            background: $dpa-go-canvas-node-selected-bg-color !important;
            border-color: $dpa-go-canvas-node-selected-border-color !important;
            border-width: px(2);
            outline: none;
          }

          &:not(.selected) {
            cursor: pointer;
            &:hover {
              background: rgba(248, 248, 248, 1);
            }
          }

          &.draggable-node {
            display: flex;
            padding: 0;
            position: relative;

            .drag-handle {
              width: px(24);
              background: $clr-color-neutral-100;
              cursor: grab;
              border-top-left-radius: px(3);
              border-bottom-left-radius: px(3);
              position: absolute;
              top: 0;
              bottom: 0;

              cds-icon {
                pointer-events: none;
                position: relative;
                top: px(7);
              }
            }

            .node-content {
              padding: px(10) px(18) px(10) px(30);
            }
          }
        }
      }

      .branch-actions-wrapper {
        > .branch-info {
          > .tasks-wrapper {
            display: flex;

            > dpa-go-canvas-tasks-node {
              display: flex;
              flex-direction: column;
              flex: 1;
              > .task-wrapper {
                &:last-child {
                  flex: 1;
                }
                > dpa-go-canvas-node-connector {
                  &:last-child {
                    flex: 1;
                  }
                }
              }

              > .no-actions-node-connector-wrapper {
                .vertical-line-with-btn {
                  align-items: flex-end;

                  .add-step-btn {
                    left: unset;
                    right: px(-20);
                    top: unset;
                  }
                }
              }
            }
          }
        }

        &.no-actions {
          > .branch-info {
            > .tasks-wrapper {
              margin-inline: 0 !important;
            }
            > .branch-label-wrapper {
              width: 100% !important;
            }
          }
        }

        &.inverse {
          > .branch-info {
            > .tasks-wrapper {
              > dpa-go-canvas-tasks-node {
                > .no-actions-node-connector-wrapper {
                  .vertical-line-with-btn {
                    align-items: flex-start;

                    .add-step-btn {
                      right: unset;
                      left: px(-20);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.read-only-mode {
      .group-node,
      .stop-workflow-node {
        .add-action-btn,
        .delete-action-btn {
          display: none;
        }
      }

      .canvas-node-connector {
        pointer-events: none;

        .add-step-btn {
          display: none !important;
        }
      }
      .side-panel {
        .canvas-popup-container {
          padding: x(2);
        }

        .add-actions-to-group-btn {
          display: none;
        }
        .group-panel-header {
          .action-buttons {
            display: none;
          }
        }
      }

      .go-canvas-node-draggable {
        &:hover {
          padding-right: unset !important;
          .go-canvas-node-draggable-overlay {
            display: none !important;
          }
        }
      }
    }

    &.is-add-by-dnd-in-progress {
      @keyframes pulsate {
        0% {
          box-shadow: 0 0 0 $clr-color-action-500;
        }
        50% {
          box-shadow: 0 0 px(12) $clr-color-action-500;
        }
        100% {
          box-shadow: 0 0 0 $clr-color-action-500;
        }
      }

      .canvas-node-connector {
        transition: none;
        opacity: 1;
        border: px(1) dashed $clr-color-action-500;
        animation: pulsate 1.2s ease-out infinite;
        position: relative;

        .drop-zone {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          > * {
            display: none !important;
          }
        }
      }
    }

    .go-canvas-node-drop-list {
      position: relative;

      .drop-placeholder {
        position: absolute;
      }
    }

    &.is-re-arrange-items-in-progress {
      .add-step-btn {
        display: none;
      }

      .drag-in-progress {
        border-style: dashed !important;
        border-width: px(2) !important;
        pointer-events: all !important;
        z-index: 1000 !important;
        touch-action: none;
        -webkit-user-drag: none;
        user-select: none;

        cds-icon[shape='drag-handle'] {
          pointer-events: all !important;
        }
      }

      .drop-placeholder {
        position: absolute;
        border: px(2) dashed $clr-color-neutral-400;
        border-radius: px(6);
      }

      .go-canvas-selectable-node,
      .canvas-node-connector,
      cds-icon {
        pointer-events: none !important;
      }
    }
  }

  .canvas-node {
    .node-header {
      display: flex;
      .node-title {
        font-weight: bold;
        font-size: px(13);
        flex: 1;
      }

      .node-icons {
        width: px(20);

        .node-error-icon {
          color: $clr-color-danger-700;
        }
      }
    }

    .node-body {
      margin-top: px(4);
      font-size: px(12);
      color: $clr-color-neutral-600;

      .node-sub-label {
        font-weight: bolder;
        padding-right: px(4);
        color: $clr-color-neutral-1000;
        white-space: nowrap;
      }
    }
  }

  .actions-container {
    .cdk-drag-preview {
      box-sizing: border-box;
      border-radius: px(4);

      .canvas-node {
        dpa-go-canvas-action-node {
          background-color: $clr-action-blue-lightest;
          border: px(2) dashed $clr-color-neutral-400 !important;
        }
      }
    }

    .cdk-drag-placeholder {
      opacity: 0;
    }

    .cdk-drag-animating {
      transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    &.cdk-drop-list-dragging {
      &:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    }
  }

  dpa-go-canvas-side-panel {
    $canvas-padding: 24;
    $nav-header-height: 120;
    $panel-header: 230;
    $panel-template-tags: 144;
    $panel-height: $panel-header + ($canvas-padding * 2) + $nav-header-height;

    .panel {
      max-width: px(800);

      .select-template-container {
        height: auto;
      }

      .list-container {
        .scroll-container,
        .list-wrapper {
          overflow: auto;
        }
        .scroll-container {
          max-height: calc(100vh - #{px($panel-height + $panel-template-tags)});
        }
        .list-wrapper {
          max-height: calc(100vh - #{px(520)});
        }
      }

      .list-wrapper {
        .scroll-container {
          max-height: none;
        }
      }

      .panel-body {
        clr-alert {
          .alert {
            margin: x(2);
          }
        }
      }
    }

    dpa-go-select-template {
      dpa-generic-list {
        dpa-filter-tags {
          .filters-row {
            margin-right: px(120);
            clr-dropdown {
              clr-dropdown-menu {
                max-height: px(300);
                overflow: auto;

                .dropdown-item,
                .quick-filters {
                  flex-shrink: 0;
                }

                .drop-down-option-clear {
                  width: px(12);
                }
              }
            }
          }
        }
      }
    }

    :is(dpa-automation-connector-action-settings-editor) {
      dpa-sections-meta-form > form > h3:not(:first-of-type) {
        margin-top: $x4 !important;
      }

      .form-container {
        padding: $x4 $x4 0 !important;

        label {
          font-weight: normal;
        }

        h3 {
          font-weight: normal;
          margin-top: 0 !important;
        }

        .test-actions-container {
          margin-inline: px(-25);
          border-top: px(1) solid $clr-color-neutral-400;
          margin-top: $x3;
          padding-inline: px(20);
          padding-block: px(10);
        }

        textarea {
          border: px(1) solid var(--clr-forms-border-color) !important;
          padding: $x2 !important;
        }

        input,
        textarea {
          font-size: x(2.16);
          font-weight: 200;
          line-height: $x4;
          padding-right: $x8 !important;
        }

        dpa-lookup-variable-input {
          .menu-opener-container {
            cds-icon {
              transform: scale(0.75);
              right: $x3 !important;
            }
          }
        }
      }
    }
  }
}

.expand-collapse-icon {
  transition: transform 250ms ease-in-out;
}

dpa-dynamic-form {
  .clr-required-mark {
    display: flex !important;
  }

  .clr-form-control {
    align-items: stretch;
  }
}

dpa-go-connector-action-selector {
  .action-item {
    &.active dpa-text-fade > div {
      color: $dpa-card-selected-bg-color !important;
    }
    &:hover dpa-text-fade > div {
      color: $dpa-action-blue-lightest !important;
    }
  }
}

// clartiy-17 overrides
.side-panel dpa-go-canvas-side-panel .modal {
  left: 0 !important;

  .modal-dialog {
    height: unset !important;
  }
}
